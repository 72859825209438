import React from "react";
import AddPost from "../../components/Navbar/AddPost";
import logo from "../../constants/img/logo.png";


function Navbar() {
	

	return (
		<div className="navbar">

			<div className="navbar-container">

				<a className="navbar-logo" href="#">

					<img src={logo} alt="Logo" />

				</a>

				<div className="add-post">
					
					<AddPost/>
					
				</div>

				<div className="search">

					<input type="text" placeholder="Search" />
					
					<div className="user-name">Admin</div>

					<img src="https://media-exp1.licdn.com/dms/image/C4D03AQEUVHF4DGkeeg/profile-displayphoto-shrink_800_800/0/1649618871878?e=1660780800&v=beta&t=MhfGEekejRJGKguJZ78mYpl53mAdKFq85IsVBZMJC9g" alt="Profile Photo" />

				</div>

			</div>

		</div>
	);
}

export default Navbar; 