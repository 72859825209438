
import React, { useContext, useEffect, useState } from "react";
import PostContext from "../../contexts/PostContext";
import { fetchOnePost } from "../../service/Service";
import threeDot from "../../constants/icons/three-dot.png";
import Like from "../../constants/icons/like.png";
import Unlike from "../../constants/icons/unlike.png";
import CommentPhoto from "../../constants/icons/comment.png";
import sendComment from "../../hooks/sendComment";
import deletePost from "../../hooks/deletePost";

function CurrentPost() {
    
	const {currentPostId, posts} = useContext(PostContext);
	const [currentPost, setCurrentPost] = useState([]);
	const [comments, setComments] = useState();

	
	useEffect(() => {
		try {
			fetchOnePost(currentPostId).then((res) => setCurrentPost(res.data));
		} catch (error) {
			console.log(error);
		}
	}, [currentPostId, comments]);


	const getInputValue = (e) => {
		setComments(e.target.value);
	};

	
	
	
	

	return (
		<div className="current-card">

			

			<div className="post">
				<div className="user-info">
					<div className="user">
						<div className="user-img"><img src={currentPost.userImg} alt={currentPost.user} /></div>

						<div className="user-name">{currentPost.user} • {currentPost.date}</div>
					</div>

					<ul className="threeDot"><img src={threeDot} alt="Three Dot" />
						<li onClick={() => deletePost(currentPostId, posts.length)}>Delete</li>
					</ul>
				</div>

				

				<div className="post-detail">
					<div className="post-title">{currentPost.title}</div>
					<div className="post-content">{currentPost.content}</div>
					<div className="post-img"><img src={currentPost?.postImg} alt="" /></div>
				</div>

				<div className="post-action">
					<a className="reply" href="#comment-input">Reply</a>
					<div className="unlike"><img src={Unlike} alt="Unlike" />{currentPost.unlike}</div>
					<div className="like"><img src={Like} alt="Like" />{currentPost.like}</div>
				</div>

				<div className="reply-comment">
					<input type="text" id="comment-input" placeholder="Reply to this thread.." onChange={getInputValue}/>
					<button type="submit" onClick={() => sendComment(comments, currentPost, currentPostId, setCurrentPost)}>Submit</button>
				</div>
			</div>

			<div className="comments">
				{currentPost?.comments?.map((item, index) => {

					return (
						<div className="comment-card" key={index}>
							<div className="user-info">
								<div className="user-img"><img src={item.userImg} alt={item.user} /></div>
								<div className="user-name">{item.user} • {item.date}</div>
							</div>

							<div className="comment-detail">
								<div className="post-content">{item.comment}</div>
							</div>
							
							<div className="comment-action">
								<div className="reply">Reply</div>
								<div className="unlike"><img src={Unlike} alt="Unlike" />{item.unlike}</div>
								<div className="like"><img src={Like} alt="Like" />{item.like}</div>
								<div className="replys"><img src={CommentPhoto} alt="CommentPhoto" />{item?.replys?.length ? item?.replys?.length : 0}</div>
							</div>

							

							<div className="comment-replys">
								{item.replys?.map((reply, index) => {
									return (
										<div className="comment-reply-card" key={index}>

											<div className="reply-user-info">

												<div className="user-img"><img src={reply.userPhoto} alt={reply.userName} /></div>

												<div className="user-name">{reply.userName} • {reply.date}</div>

											</div>

											<div className="reply-detail">{reply.replyComment}</div>

											<div className="reply-action">
												<div className="unlike"><img src={Unlike} alt="Unlike" />{reply.unlike}</div>

												<div className="like"><img src={Like} alt="Like" />{reply.like}</div>
											</div>
										</div>
									);
								})}
							</div>

							
						</div>
					);
				})}
			</div>

		</div>
	);
}

export default CurrentPost;