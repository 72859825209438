import React from "react";
import CurrentPost from "../../components/Current-Post";
import Posts from "../../components/Posts";
import Users from "../../components/Users";
import Navbar from "../Navbar";

function Index() {
	return ( 
		<div className="app">
			<Navbar />

			<div className="row content">
				<div className="col-3 posts">
					<Posts/>
				</div>
				<div className="col-6 current-post">
					<CurrentPost/>
				</div>
				<div className="col-3 users">
					<Users/>
				</div>
			</div>
		</div>
	);
}

export default Index;