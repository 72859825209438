/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/prop-types */

import { createContext, useEffect, useState } from "react";
import { fetchPost } from "../service/Service";

const PostContext = createContext();

export const PostProvider = ({ children }) => {
	const [posts, setPosts] = useState([]);
	const [sortedPosts, setSortedPosts] = useState([]);
	const [popularUsers, setPopularUsers] = useState([]);
	const [postCategory, setPostCategory] = useState(true);
	const [currentPostId, setCurrentPostId] = useState();



	useEffect(() => { setCurrentPostId(sortedPosts[0]?.postId); }, [sortedPosts]);


	// Fetch Posts
	useEffect(() => {
		try {
			fetchPost().then(res => {
				setPosts(res.data);
			});

		} catch (error) {
			console.log(error);
		}
	}, []);

	// Fetch Posts and Sort by Category
	useEffect(() => {
		try {
			fetchPost().then(res => {
				postCategory ? setSortedPosts(res.data.sort((a, b) => b.like - a.like)) : setSortedPosts(res.data.reverse());
			});

		} catch (error) {
			console.log(error);
		}
	}, [postCategory]);

	// Fetch Posts and Sort by Populatiry
	useEffect(() => {
		try {
			fetchPost().then(res => {
				setPopularUsers(res.data.sort((a, b) => b.userEntry - a.userEntry));
			});

		} catch (error) {
			console.log(error);
		}
	}, []);


	const values = {
		posts, setPosts, postCategory, setPostCategory, sortedPosts, popularUsers, currentPostId, setCurrentPostId
	};

	return (
		<PostContext.Provider value={values}>{children}</PostContext.Provider>
	);
};

export default PostContext;