import React, { useContext } from "react";
import PostContext from "../../contexts/PostContext";
import PostCard from "./PostCard";

function Posts() {
	const {postCategory, setPostCategory, sortedPosts} = useContext(PostContext);
	

	return (
		<>
			<div className="post-category">
				<div className={`popular ${postCategory && "active"}`} onClick={() => setPostCategory(true)}>Popular</div>
				<div className={`recents ${!postCategory && "active"}`} onClick={() => setPostCategory(false)}>Recents</div>
			</div>
			<div className="all-post">
				{sortedPosts.map((item, index) => {
					return (
						<div key={index}>
							{PostCard(item?.title, item?.unlike, item?.like, item?.comments?.length, item?.date.slice(0, 10), item?.postId, item)}
						</div>
					);
				})}
			</div>
		</>
	);
}

export default Posts;