import axios from "axios";

export const baseURL = "https://mobiversite-api.emredag.com.tr/posts";
// export const baseURL = "http://localhost:5500/posts";

export const fetchPost = async () => {
	const data = await axios.get(baseURL);
	return data;
};

export const fetchAddPost = async (input) => {
	const data = await axios.post(baseURL, input);
	return data;
};

export const fetchOnePost = async (productId) => {
	const data = await axios.get(`${baseURL}/${productId}`);
	return data;
};

export const fetchSendComment = async (productId, input) => {
	const data = await axios.patch(`${baseURL}/${productId}`, input);
	return data;
};

export const fetchDeletePost = async (productId) => {
	const data = await axios.delete(`${baseURL}/${productId}`);
	return data;
};