import React from "react";
import Index from "./pages/Index-Page/Index";
import "./styles/style.scss";
import "./styles/reset.scss";
import { PostProvider } from "./contexts/PostContext";

function App() {
	return (
		<PostProvider><Index /></PostProvider>

	);
}

export default App;
