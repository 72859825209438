import React, { useContext } from "react";
import threeDot from "../../constants/icons/three-dot.png";
import Like from "../../constants/icons/like.png";
import Unlike from "../../constants/icons/unlike.png";
import Comment from "../../constants/icons/comment.png";
import PostContext from "../../contexts/PostContext";
import deletePost from "../../hooks/deletePost";

function PostCard(title, unlike, like, comment, date, postId, item) {
	const {currentPostId, setCurrentPostId, posts} = useContext(PostContext);

	return (
		<div className={`post-card ${postId === currentPostId && "active-card"}`}>

			<ul className="threeDot"><img src={threeDot} alt="Three Dot" />
				<li onClick={() => deletePost(currentPostId, posts.length)}>Delete</li>
			</ul>

			<div className="post-card-title" onClick={() => {setCurrentPostId(item.postId);}}>
				{title}
			</div>

			<div className="post-card-action">
				<div className="post-card-action-left">
					<span><img src={Unlike} alt="Unlike" /> {unlike}</span>
					<span><img src={Like} alt="Like" /> {like}</span>
					<span><img src={Comment} alt="Comment" /> {comment ? comment : "0"}</span>
				</div>
				<div className="post-card-action-right">{date}</div>
			</div>
		</div>
	);
}

export default PostCard;