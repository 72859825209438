import React, { useContext } from "react";
import PostContext from "../../contexts/PostContext";

function Users() {
	const {popularUsers} = useContext(PostContext);

	return (
		<div>
			<div className="users-title">Popular Users</div>
			<div className="popular-users">
				{popularUsers.map((item, index) => {
					return (
						<div className="user-card" key={index}>
							<div className="user-img">
								<img src={item?.userImg} alt={item.user} />
							</div>
							<div className="user-info">{item?.user} <br /> <span>{item.userEntry} Entry</span> </div>
							
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default Users;