import React, { useContext, useState } from "react";
import { fetchAddPost } from "../../service/Service";
import { v4 as uuidv4 } from "uuid";
import PostContext from "../../contexts/PostContext";

function AddPost() {
	const [userName, setUserName] = useState();
	const [userImg, setUserImg] = useState();
	const [title, setTitle] = useState();
	const [content, setContent] = useState();
	const [image, setImage] = useState();

	const {setPostCategory} = useContext(PostContext);
	
	const postTitle = (e) => {
		setTitle(e.target.value);
	};

	const postContent = (e) => {
		setContent(e.target.value);
	};

	const postImage = (e) => {
		setImage(e.target.value);
	};

	const postUserName = (e) => {
		setUserName(e.target.value);
	};

	const postUserImg = (e) => {
		setUserImg(e.target.value);
	};

	const sendPost = () => {
		const post = {
			title: title,
			content: content,
			user: userName,
			userEntry: Math.floor(Math.random() * 1000),
			userImg: userImg,
			postImg: image,
			date: new Date().toLocaleString(),
			like: 0,
			unlike: 0,
			postId: uuidv4()
		};

		if(title && content && userName && userImg && image){
			fetchAddPost(post).then(() => setPostCategory(false));
		} else {
			window.alert("Tüm alanları doldurunuz.");
		}
	};

	return (
		<>
			<div className="add-info">Add Post</div>
			<input type="text" placeholder="User Name" onChange={postUserName} />
			<input type="text" placeholder="User Img URL" onChange={postUserImg} />
			<input type="text" placeholder="Post Title" onChange={postTitle} />
			<input type="text" placeholder="Post Content" onChange={postContent} />
			<input type="text" placeholder="Post Img URL" onChange={postImage} />
			<div className="send-post" onClick={sendPost}>Post</div>
		</>
	);
}

export default AddPost;