import { fetchOnePost, fetchSendComment } from "../service/Service";
import { v4 as uuidv4 } from "uuid";


function sendComment(comments, currentPost, currentPostId, setCurrentPost) {

	const comment = {
		comment: comments,
		commentId: uuidv4(),
		date: new Date().toLocaleString(),
		user: "Admin",
		userImg: "https://media-exp1.licdn.com/dms/image/C4D03AQEUVHF4DGkeeg/profile-displayphoto-shrink_800_800/0/1649618871878?e=1660780800&v=beta&t=MhfGEekejRJGKguJZ78mYpl53mAdKFq85IsVBZMJC9g",
		like: 0,
		unlike: 0,
	};


	const currentComment = currentPost.comments;

	const newComment = {
		comments: currentComment ? [...currentComment, comment] : [comment],
	};


	comments && fetchSendComment(currentPostId, newComment).then(() => fetchOnePost(currentPostId).then((res) => {
		setCurrentPost(res.data);
		console.log(res.data);
	}));
}

export default sendComment;